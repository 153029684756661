import React, {useState} from 'react';
import { ReactComponent as Checkmark } from "./checkmark.svg"
import { ReactComponent as CheckmarkWhite } from "./checkmark-white.svg"
import { ReactComponent as StripeIcon } from "./stripe-icon.svg"
import { ReactComponent as StripeIconDark } from "./stripe-icon-dark.svg"
import TurnstileWidget from "../TurnstileWidget";

const PricingDisplayPopup = ({title, description}) => {
    const [titleText, setTitleText] = useState(title ?? 'Unlock Full Access With A Free Account');
    const [descriptionText, setDescriptionText] = useState(description ?? 'Create an account to unlock full site usage. It\'s free to join, no CC required.');

    const checkoutPage = () => {
            window.open("https://buy.stripe.com/bIYg048vTeER1DabIK", '_blank');
            return;
    }

    return (
        <div className="flex flex-col justify-center items-center h-full font-ivyora-display mb-12">
            <img src={require("../Assets/dog-book-logo-glasses.png")} alt="apple image" className="w-25 h-[80px]"/>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 font-ivyora-display">{titleText}</h2>
            <p className="mt-2 text-center text-sm text-gray-600 font-ivyora-display">
                {descriptionText}
            </p>
            <p className="my-6 text-xl font-bold">Upgrade To Premium</p>
            <div className="h-[480px] w-[400px] border-indigo-600 rounded-md border-2 flex flex-col">
                <div className="pl-2">
                    <p className="font-bold mt-4 pb-0.5">Premium</p>
                    <p className="text-xs">All the tools to boost your classroom engagement.</p>
                </div>
                <div className="mt-6 text-xl font-bold full flex justify-center mb-6">
                    <p>Winter Sale! 33% off current price</p>
                </div>
                <div className="flex pl-12 items-end justify-center pr-24">
                    <p className="line-through text-3xl px-2">$6</p>
                    <p className="font-bold text-3xl text-indigo-500">$4 </p>
                    <p className="pl-1">monthly</p>
                </div>

                <div className="flex items-center mb-2">
                    <div onClick={() => checkoutPage()} className="flex items-center cursor-pointer justify-center py-2 px-1 mx-4 mt-4 w-full md:w-[300px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                        <p className="">Upgrade Now</p>
                    </div>
                    <div className="flex pt-2 pr-4">
                        <img className="h-[22px] pt-1 pr-1" src={require('./secure-lock.png')}/>
                        <img className="h-[22px] pt-1" src={require('./stripe-icon.png')}/>
                    </div>
                </div>
                <div className="pl-2 mt-6">
                    <p className="italic font-bold">Comes with</p>
                    <div className="pl-4">
                        <div className="flex">
                            <p className="mr-2">Unlimited Daily Usage</p>
                            <Checkmark className="h-[22px]" />
                        </div>
                        <div className="flex mb-0.5">
                            <p className="mr-2">Premium Creator Tools</p>
                            <Checkmark className="h-[22px]" />
                        </div>
                        <div className="flex mb-0.5">
                            <p className="mr-2">Access To All Features</p>
                            <Checkmark className="h-[22px]" />
                        </div>
                        <div className="flex mb-0.5">
                            <p className="mr-2">100% Ad Free</p>
                            <Checkmark className="h-[22px]" />
                        </div>
                        <div className="flex mb-0.5">
                            <p className="mr-2">Access To Entire Content Library</p>
                            <Checkmark className="h-[22px]" />
                        </div>
                        <div className="flex mb-0.5">
                            <p className="mr-2">Cancel Anytime</p>
                            <Checkmark className="h-[22px]" />
                        </div>
                    </div>
                </div>
            </div>

            <p className="mt-2 text-center cursor-pointer text-sm text-indigo-600 hover:text-indigo-400">
                <a href='/login'>Already a user? Log in here</a>
            </p>
        </div>
    )
}

export default PricingDisplayPopup;
