import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import * as sessionActions from "../../../store/session";
import {useCookies} from "react-cookie";
import {csrfFetch} from "../../../store/csrf";
import SignupFormPage from "../../SignupFormPage";
import PricingDisplay from "../../PricingDisplay";
import { ReactComponent as LeftArrow } from "./left-arrow.svg"
import {addUserinteraction} from "../../../utils/user-tracking";
import SignupFormPopup from "../../SignupFormPopup";
import PricingDisplayPopup from "../../PricingDisplayPopup";

const ResourceForm = ({setShowGeneratedContent, setSubject, setLanguage,
                          setTestQuestionTypes, setNumberOfQuestions, setWorksheetTypes, worksheetTypes, setQuestionTypes,
                          setSelectedChemicals, setSelectedEquipment, setGrade, setType, setSelectedTek, userId,
                          setShowNotificationContainer, setNotificationContainer, advsArr, advsBannerArr, startingType, useTek}) => {

    const dispatch = useDispatch();
    const [useTeks, setUseTeks] = useState(useTek);
    const [resourceType, setResourceType] = useState(startingType);
    const [resouceFormFields, setResourceFormFields] = useState(null);
    const [worksheetSection, setWorksheetSection] = useState([]);
    const [sectionTypes, setSectionTypes] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [refCount, setRefCount] = useState(1);
    const subjects = useSelector((state) => state.session.subjects);
    const grades = useSelector((state) => state.session.grades);
    const teks = useSelector((state) => state.session.teks);
    const [cookies, setCookie] = useCookies(['usageCount']);
    const [usageCount, setUsageCount] = useState(cookies.usageCount || 0);
    const sessionSubscription = useSelector(state => state.session.usersubscription)
    const [loopCount, setLoopCount] = useState(0);
    const [totalLoops, setTotalLoops] = useState(30);
    const [ctaIndex, setCtaIndex] = useState(0);
    const [advsBannerIndex, setAdvsBannerIndex] = useState(0);
    const [pricingDiv, setPricingDiv] = useState(<div className="animate-slideIn w-full absolute z-10 flex justify-center"><div className="bg-white w-[900px] flex justify-center shadow-[0_0_8px_2px_rgba(79,69,228,0.5)] focus:shadow-[0_0_12px_4px_rgba(0,255,255,0.75)] outline-none"><PricingDisplayPopup title={"Out Of Daily Attempts"} description={"Check back tomorrow to continue creating content, or upgrade to Premium to unlock unlimited usage and full site features."} /></div></div>);

    useEffect( () => {
        if (!subjects) {
            dispatch(sessionActions.getTekSubjects());
        }
    }, [subjects])

    useEffect(() => {
        if (startingType !== 'freestyle-lessonplan' || startingType !== 'tek-lessonplan') {
            updateFormInputs(startingType);
        }
    }, [startingType]);

    const fetchGrades = async(e) => {
        const subject = e;
        setSelectedSubject(subject)
        setSubject(subject)
        await dispatch(sessionActions.fetchTekGrades(subject));
    }

    const fetchTeks = async(e) => {
        const grade = e;
        setGrade(grade)
        await dispatch(sessionActions.fetchTeks(grade, selectedSubject));
        setCheckedItems({});
    }

    const fetchUsageCount = async() => {
        const response = await csrfFetch('/api/teks/fetch-user-content-count', {
            method: 'POST',
            body: JSON.stringify({
                userId,
            }),
        })
        const data = await response.json();
        return data.userContentCount;
    }
    const incrementUsageCount = () => {
        if (!userId || userId === 1) {
            setUsageCount(parseInt(usageCount) + 1);
            setCookie('usageCount', parseInt(usageCount) + 1, { path: '/' });
        } else {
            setCookie('usageCount', 0, { path: '/' });
        }
    };

    const createResource = async(e) => {
        e.preventDefault();
        if (userId === 1 || userId === null) {
            incrementUsageCount();
            if (usageCount >= 2) {
                setShowNotificationContainer(true)
                setNotificationContainer(<div className="animate-slideIn w-full absolute z-10 flex justify-center"><div className="bg-white w-[900px] flex justify-center shadow-[0_0_8px_2px_rgba(79,69,228,0.5)] focus:shadow-[0_0_12px_4px_rgba(0,255,255,0.75)] outline-none"><SignupFormPopup /></div></div>);
                return;
            } else {
                setShowGeneratedContent(true);
                return;
            }
        }

        if (sessionSubscription?.is_active) {
            setShowGeneratedContent(true);
            return;
        } else {
            let userUsageCount = await fetchUsageCount();
            if (userUsageCount > 3) {
                setShowNotificationContainer(true);
                setNotificationContainer(
                    pricingDiv)
                return;
            }
            setShowGeneratedContent(true);
            return;
        }
    }

    const updateResourceType = (e) => {
        e.preventDefault();
        setResourceType(e.target.value)
        setType(e.target.value)
        updateFormInputs(e.target.value)
    }

    const updateTeks = async() => {
        setUseTeks(!useTeks);
        setResourceFormFields(resource => {return <div></div>})
    }
    const updateQuestionTypes = async (questionType) => {
        setQuestionTypes(prevQuestionTypes => {
            // Check if the questionType is already in the array
            if (prevQuestionTypes.includes(questionType)) {
                // Remove the element if it exists
                return prevQuestionTypes.filter(type => type !== questionType);
            } else {
                // Add the element if it doesn't exist
                return [...prevQuestionTypes, questionType];
            }
        });
    };

    const updateWorksheetTypes = async (worksheetType) => {
        setWorksheetTypes(prevSectionTypes => {
            // Check if the questionType is already in the array
            if (prevSectionTypes.includes(worksheetType)) {
                // Remove the element if it exists
                return prevSectionTypes.filter(type => type !== worksheetType);
            } else {
                // Add the element if it doesn't exist
                return [...prevSectionTypes, worksheetType];
            }
        });
    }

    const openRefPage = async (url, advId) => {
        let user_id = userId || 1;
        let interaction_type = "click";
        let interaction_details = JSON.stringify({
            type: "click",
            detail: "advertisement click",
            advId: advId
        });
        let page_url = url;
        window.open(url, "_blank")
        await addUserinteraction(user_id, interaction_type, interaction_details, page_url)
    }

    const updateRefCount = (direction) => {
        if (direction === 'forward' && refCount < 3) {
            let count = refCount + 1;
            setRefCount(count)
        } else if (direction === 'backward' && refCount > 1) {
            let count = refCount - 1;
            setRefCount(count)
        }
    }

    const rotateAdvBanner = (direction) => {
        if (advsBannerIndex >= advsBannerArr.length - 1 || advsBannerIndex < 0) {
            setAdvsBannerIndex(0);
            return;
        }
        if (direction === "forward") {
            setAdvsBannerIndex((prevIndex) => prevIndex + 1)
        } else if (direction === "backward" && advsBannerIndex > 0) {
            setAdvsBannerIndex((prevIndex) => prevIndex - 1)
        }
    }

    useEffect(() => {
        if (advsArr.length > 0) {
            const intervalId = setInterval(() => {
                setCtaIndex((prevIndex) => (prevIndex + 1) % advsArr.length)
            }, 5000);

            return () => clearInterval(intervalId) ;
        }
    }, [advsArr.length])

    const updateFormInputs = (type) => {
        setResourceFormFields(<div></div>);
        setWorksheetSection(<div></div>);

        switch(type) {
            case 'freestyle-worksheet':
            case 'tek-worksheet':
                setResourceFormFields(
                    <div>
                        <div>
                            <p>Worksheet Sections:</p>
                            <div className="flex flex-col justify-start">
                                <div className="mt-1">
                                    <input className="mr-1" type="checkbox" value="Handout" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Handout</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Topic Summary" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Topic Summary</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="In-depth Topic Analysis" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>In-depth Topic Analysis</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Vocabulary Blanks" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Vocabulary Blanks</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Term Matching" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Term Matching</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Open Ended Questions" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Open Ended Questions</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Practice Problems" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Practice Problems</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Fill in the blank" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Fill in the blank</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Word Search" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Word Search</label>
                                </div>
                                <div>
                                    <input className="mr-1" type="checkbox" value="Writing Prompts" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Writing Prompts</label>
                                </div>
                            </div>
                        </div>
                    </div>
                )

                setWorksheetSection(
                    <div>

                    </div>
                )
                break;
            case 'freestyle-test':
                case 'tek-test' :
                setResourceFormFields(
                    <div>
                        <div>
                            <div>
                                <p>Question Types</p>
                                <div className="flex flex-col justify-start">
                                    <div>
                                        <input className="mr-1" type="checkbox" value="Multiple Choice" onChange={(e) => updateQuestionTypes(e.target.value)} /><label>Multiple Choice</label>
                                    </div>
                                    <div>
                                        <input className="mr-1" type="checkbox" value="Short Answer" onChange={(e) => updateQuestionTypes(e.target.value)} /><label>Short Answer</label>
                                    </div>
                                    <div>
                                        <input className="mr-1" type="checkbox" value="Essay" onChange={(e) => updateQuestionTypes(e.target.value)} /><label>Essay</label>
                                    </div>
                                    <div>
                                        <input className="mr-1" type="checkbox" value="Fill in the blank" onChange={(e) => updateQuestionTypes(e.target.value)} /><label>Fill in the blank</label>
                                    </div>
                                    <div>
                                        <input className="mr-1" type="checkbox" value="True or False" onChange={(e) => updateQuestionTypes(e.target.value)} /><label>True or False</label>
                                    </div>
                                    <div>
                                        <input className="mr-1" type="checkbox" value="Term Matching" onChange={(e) => updateQuestionTypes(e.target.value)} /><label>Term Matching</label>
                                    </div>
                                    <div>
                                        <input className="mr-1" type="checkbox" value="Writing Prompts" onChange={(e) => updateQuestionTypes(e.target.value)} /><label>Writing Prompts</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <label>
                                Number of Questions
                                <input
                                    className="h-10 border border-grey-100 px-4 w-full mb-4"
                                    type="text" onChange={e => setNumberOfQuestions(e.target.value)}
                                    placeholder="10"
                                />
                            </label>
                        </div>
                    </div>
                )
                break;
        }
    }

    return (
        <div className="py-12 flex flex-col justify-center items-center">
            <form className="flex flex-col justify-center items-center px-8 w-[94%] md:w-[900px] pt-8 bg-[#f4f4f4] min-h-[28rem] mb-12">
                <div class="md:w-[600px]">
                    <div className="flex justify-between">
                        <p>Subject:</p>
                        {/*{!useTeks ? <a className="text-indigo-600 cursor-pointer" onClick={() => updateTeks()}>Use TEKS</a> :*/}
                        {/*    <a className="text-indigo-600 cursor-pointer" onClick={() => updateTeks()}>Use Freestyle</a>*/}
                        {/*}*/}
                    </div>

                    {!useTeks &&
                    <div>
                    <label className="w-full">
                        <input
                            type="text"
                            //value={selectedSubject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="h-10 border border-grey-100 px-4 w-full mb-4"
                            placeholder="Balancing chemical equations"
                        />
                    </label>
                    <label className="w-full">
                        Grade:
                        <input
                            type="text"
                            //value={selectedSubject}
                            onChange={(e) => setGrade(e.target.value)}
                            className="h-10 border border-grey-100 px-4 w-full mb-4"
                            placeholder="8th"
                        />
                    </label>
                    </div>}

                    {useTeks &&
                        <div>
                            <label className="w-full">
                                <select className="h-10 border border-grey-100 px-4 w-full mb-4"
                                        onChange={(e) => fetchGrades(e.target.value)}
                                >
                                    <option>Select A Subject</option>
                                    {subjects?.map((subject, index) => (
                                        <option key={index} value={subject.subject}>
                                            {subject.subject}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label className="w-full">
                                Grade:
                                <select className="h-10 border border-grey-100 px-4 w-full mb-4" onChange={(e) => fetchTeks(e.target.value)}>
                                    <option>Select A Grade</option>
                                    {grades?.map((grade, index) => (
                                        <option key={index} value={grade.grade}>
                                            {grade.grade}
                                        </option>
                                        ))}
                                </select>
                            </label>
                            <label className="w-full">
                                TEKS:
                                <select className="h-10 border border-grey-100 px-4 w-full mb-4"
                                        onChange={(e) => setSelectedTek(e.target.value)}
                                >
                                    <option>Select A TEK</option>
                                    {teks?.map((tek, index) => (
                                        <option key={index} value={tek.tek}>
                                            {tek.tek}
                                        </option>
                                        ))}
                                </select>
                            </label>
                        </div>}
                </div>
                <div className="md:w-[600px]">
                    <label className="w-full">
                        Language:
                        <select className="h-10 border border-grey-100 px-4 w-full mb-4" onChange={e => setLanguage(e.target.value)}>
                            <option value="english">English</option>
                            <option value="spanish">Spanish</option>
                            <option value="french">French</option>
                            <option value="german">German</option>
                        </select>
                    </label>
                    {useTeks && <label className="w-full">
                        Resource Type:
                        <select
                            className="h-10 border border-grey-100 px-4 w-full mb-4"
                            value={resourceType}
                            onChange={e => updateResourceType(e)}
                        >
                            <option value={!useTeks ? "freestyle-lessonplan" : "tek-lessonplan"}>Lesson Plan</option>
                            <option value={!useTeks ? "freestyle-worksheet" : "tek-worksheet"}>Worksheet</option>
                            <option value={!useTeks ? "freestyle-test" : "tek-test"}>Test</option>
                            {/*<option value={!useTeks ? "freestyle-lab" : "tek-lab" }>Science Lab</option>*/}
                        </select>
                    </label>}
                    <label className="w-full">
                        {resouceFormFields}
                    </label>
                    {worksheetSection}
                    <br />
                    {/*Add advertisement container here*/}
                    {/*!sessionSubscription &&
                    <div className="w-full mt-16 h-[160px] hidden md:flex flex-col items-center justify-center">
                        <p className="font-ivyora-display mx-2 text-xl mb-2">Classroom Essentials</p>
                        <div className="border-indigo-50 border-4">
                            <div className="w-[320px] cursor-pointer flex items-center h-[160px] border-black border-2 bg-white">
                                <img className="h-full max-w-[180px]" src={advsArr[ctaIndex]?.image_url} onClick={() => openRefPage(advsArr[ctaIndex]?.ref_link, advsArr[ctaIndex]?.ta_adv_id)} />
                                <div className="flex flex-col items-center justify-center">
                                    <p className="font-ivyora-display text-md pl-10 w-[120px]" onClick={() => openRefPage(advsArr[ctaIndex]?.ref_link, advsArr[ctaIndex]?.ta_adv_id)} >{advsArr[ctaIndex]?.title}</p>
                                    <p className="font-ivyora-display text-sm">${advsArr[ctaIndex]?.price}</p>
                                    <p className="font-ivyora-display text-xs pl-2 text-left">at {advsArr[ctaIndex]?.brand}</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full text-end font-ivyora-display">
                            <p className="text-slate-400">Ad</p>
                        </div>
                    </div> */}
                </div>
                <div className="col-start-4 col-end-7 mb-4">
                    <button className="justify-center py-2 px-4 mx-4 mt-8 w-full md:w-[300px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700" onClick={e => createResource(e)}>Create</button>
                </div>
            </form>
            {/*{!sessionSubscription &&*/}
            {/*    <div className="w-full mt-16 h-[160px] flex flex-col items-center justify-center">*/}
            {/*        <p className="font-ivyora-display mx-2 text-xl mb-2">Classroom Essentials</p>*/}
            {/*        <div className="border-indigo-50 border-4">*/}
            {/*            <div className="w-[320px] cursor-pointer flex items-center h-[160px] border-black border-2 bg-white">*/}
            {/*                <img className="h-full max-w-[180px]" src={advsArr[ctaIndex]?.image_url} onClick={() => openRefPage(advsArr[ctaIndex]?.ref_link, advsArr[ctaIndex]?.ta_adv_id)} />*/}
            {/*                <div className="flex flex-col items-center justify-center">*/}
            {/*                    <p className="font-ivyora-display text-md pl-10 w-[120px]" onClick={() => openRefPage(advsArr[ctaIndex]?.ref_link, advsArr[ctaIndex]?.ta_adv_id)} >{advsArr[ctaIndex]?.title}</p>*/}
            {/*                    <p className="font-ivyora-display text-sm">${advsArr[ctaIndex]?.price}</p>*/}
            {/*                    <p className="font-ivyora-display text-xs pl-2 text-left">at {advsArr[ctaIndex]?.brand}</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="w-full text-end font-ivyora-display">*/}
            {/*            <p className="text-slate-400">Ad</p>*/}
            {/*        </div>*/}
            {/*    </div>}*/}
            {/*{!sessionSubscription &&*/}
            {/*    <div className="w-full">*/}
            {/*        <div className="flex justify-center items-center h-36 mt-36">*/}
            {/*                <LeftArrow onClick={() => rotateAdvBanner("backward")} className="h-[100px] md:h-[50px] md:pr-12 cursor-pointer" />*/}
            {/*            {*/}
            {/*                advsBannerArr[advsBannerIndex]?.map((ad, index) => (*/}
            {/*                    <div className="flex flex-col justify-center items-center">*/}
            {/*                        <img className="cursor-pointer h-18 md:h-auto px-4" src={ad?.image_url} onClick={() => openRefPage(ad?.ref_link, ad?.ta_adv_id)} />*/}
            {/*                        <p className="cursor-pointer font-ivyora-display text-[8px] md:text-sm w-[40%] mt-6" onClick={() => openRefPage(ad?.ref_link, ad?.ta_adv_id)} >{ad?.title}</p>*/}
            {/*                        <p className="cursor-pointer font-ivyora-display text-[8px] md:text-sm text-left mt-2">${ad?.price}</p>*/}
            {/*                        <p className="font-ivyora-display text-[8px] md:text-sm text-left">at {ad?.brand}</p>*/}
            {/*                    </div>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*            <LeftArrow onClick={() => rotateAdvBanner("forward")} className="h-[100px] md:h-[50px] md:ml-12 cursor-pointer" style={{transform: "rotate(180deg)"}} />*/}
            {/*        </div>*/}
            {/*        <p className="text-end font-ivyora-display mt-12 text-slate-400">Ad</p>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    )
}

export default ResourceForm;
