import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';

const Share = () => {
    const [showShareContainer, setShowShareContainer] = useState(false);
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const location = useLocation();
    const [copyStatus, setCopyStatus] = useState('');

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(`www.teachersaide.io${location.pathname}${location.search}`);
            setCopyStatus('Copied to clipboard!');
            setTimeout(() => {
                setCopyStatus('');
                setShowCopyMessage(true);
                setTimeout(() => {
                    setShowCopyMessage(false);
                }, 1400)
            }, 200); // Clear the status after 2 seconds
        } catch (error) {
            setCopyStatus('Failed to copy!');
            console.error('Error copying to clipboard:', error);
        }
    };

    const xRedirect = () => {
        const text = `Check out this worksheet I created at Teachersaide.io: \n www.teachersaide.io${location.pathname}${location.search}`
        window.open(`https://twitter.com/intent/tweet?text=${text}`, "_blank")};
    return (
        <div id="share-container" className="flex flex-col pl-4">
            {showCopyMessage && <div className="bg-gray-200 absolute text-indigo-600 z-4 top-12 right-12 rounded-md h-16 flex items-center px-2">
                <p>Copied Link To Clipboard.</p>
            </div>}
            {showShareContainer && <div id="share-type-container" className="flex bg-indigo-600 opacity-60 z-12 top-36 md:top-32">
                <div onClick={() => setShowShareContainer(prevVal => !prevVal)} className="flex items-center bg-indigo-800 h-full hover:cursor-pointer hover:bg-indigo-200">
                    <img
                         className="w-4 h-2 pl-1 mr-2"
                         src={require("../Assets/back-arrow.png")} />
                </div>
                <div className="hover:cursor-pointer hover:bg-indigo-200 py-2 pl-2 flex justify-center">
                    <img onClick={() => handleCopy()}
                         className="w-8 hover:cursor-pointer hover:bg-indigo-200 mr-2"
                         src={require("../Assets/copy-link.png")} />
                </div>
                <div className="hover:cursor-pointer hover:bg-indigo-200 py-2 pl-2 pr-2 flex justify-center">
                    <img onClick={() => xRedirect()}
                         className="w-8"
                         src={require("../Assets/twitter-x-icon.png")} />
                </div>
            </div>}
            {!showShareContainer && <button id="share-button"
                     className="h-8 w-24 rounded bg-indigo-600 hover:bg-indigo-300 text-white font-ivyora-display"
                     onClick={() => setShowShareContainer(prevVal => !prevVal)}
            >
                Share</button>}
        </div>
    )
}

export default Share;
