import React, {useEffect, useState} from 'react';

const AdContainer = ({ad}) => {
    const [advertisement, setAdvertisement] = useState(JSON.parse(ad))

    const openAdWindow = () => {
        window.open(advertisement.ref_link, "_blank")
    }

    return (
        <div className="w-full cursor-pointer text-white flex justify-center items-center" onClick={openAdWindow}>
            <img className="h-[90px] [w-100px]" src={advertisement.image_url} />
            <div className="flex flex-col pl-6">
                <p>{advertisement.title}</p>
                <p>${advertisement.price}</p>
            </div>
        </div>
    )
}

export default AdContainer;
