import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {csrfFetch} from "../../store/csrf";
import { ReactComponent as LoadingIcon } from "../Assets/loading-icon.svg"
import IsdNav from "../MainGen/IsdNav";
import './index.css';
import RichTextEditor from "./RichTextEditor";
import DetailedDashboard from "../DetailedDashboard";
import { ReactComponent as Lightning } from "../Assets/lightning-bolt1.svg"
import * as sessionActions from "../../store/session";
import Footer from "../Footer";
import {useCookies} from "react-cookie";
import SignupFormPage from "../SignupFormPage";
import PricingDisplay from "../PricingDisplay";
import SignupFormPopup from "../SignupFormPopup";
import PricingDisplayPopup from "../PricingDisplayPopup";
import AdBanner from "../AdBanner";
import CookiesBanner from "../MainGen/CookiesBanner";
import PrintButton from "../PrintButton";
import Share from "../Share";

const AdvSandbox = () => {
    const [response1, setResponse1] = useState(null);
    const [response2, setResponse2] = useState(null);
    const [response3, setResponse3] = useState(null);
    const [chapters, setChapters] = useState([]);
    const [chapter, setChapter] = useState(null);
    const [section, setSection] = useState(null);
    const [sections, setSections] = useState([]);
    const [topics, setTopics] = useState([]);
    const [topic1, setTopic1] = useState(null);
    const [topic2, setTopic2] = useState(null);
    const [topic3, setTopic3] = useState(null);
    const [worksheetType1, setWorksheetType1] = useState(null);
    const [worksheetType2, setWorksheetType2] = useState(null);
    const [worksheetType3, setWorksheetType3] = useState(null);
    const [showLoadingSection1, setShowLoadingSection1] = useState(false);
    const [showLoadingSection2, setShowLoadingSection2] = useState(false);
    const [showLoadingSection3, setShowLoadingSection3] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showMyAccount, setShowMyAccount] = useState(false);
    const [showAddText, setShowAddText] = useState(true);
    const [language, setLanguage] = useState("English");
    const [books, setBooks] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [cookies, setCookie] = useCookies(['usageCount']);
    const [usageCount, setUsageCount] = useState(cookies.usageCount || 0);
    const [userId, setUserId] = useState(null);
    const sessionSubscription = useSelector(state => state.session.usersubscription)
    const [showNotificationContainer, setShowNotificationContainer] = useState(false);
    const [notificationContainer, setNotificationContainer] = useState(null);
    const [notificationPopupContainer, setNotificationPopupContainer] = useState(null);
    const [showNotificationPopupContainer, setShowNotificationPopupContainer] = useState(false);
    const [missingTypeError1, setMissingTypeError1] = useState(false);
    const [missingTypeError2, setMissingTypeError2] = useState(false);
    const [missingTypeError3, setMissingTypeError3] = useState(false);
    const [missingFormError, setMissingFormError] = useState(false);
    const [signUpDiv, setSignUpDiv] = useState(<div className="animate-slideIn w-full absolute z-10 flex justify-center"><div className="bg-white w-[900px] flex justify-center shadow-[0_0_8px_2px_rgba(79,69,228,0.5)] focus:shadow-[0_0_12px_4px_rgba(0,255,255,0.75)] outline-none"><SignupFormPopup title={"Create An Account To Continue"} description={"We're excited that you're using our site, please create an account to confirm you're human. It's free, no CC required to sign up."} /></div></div>);
    const [pricingDiv, setPricingDiv] = useState(<div className="animate-slideIn w-full absolute z-10 flex justify-center"><div className="bg-white w-[900px] flex justify-center shadow-[0_0_8px_2px_rgba(79,69,228,0.5)] focus:shadow-[0_0_12px_4px_rgba(0,255,255,0.75)] outline-none"><PricingDisplayPopup title={"Out Of Daily Attempts"} description={"Check back tomorrow to continue creating content, or upgrade to Premium to unlock unlimited usage and full site features."} /></div></div>);
    const [showCookiesBanner, setShowCookiesBanner] = useState(true);

    useEffect(() => {
        async function setUserAndSubscription() {
            if (sessionUser) {
                setUserId(sessionUser.id);
                dispatch(sessionActions.getSubscription(sessionUser.id));
            } else {
                setUserId(1);
            }
        }

        setUserAndSubscription();
    }, [sessionUser])

    useEffect(() => {
        const fetchBooks = async() => {
            const response = await csrfFetch('/api/books/fetch-books', {
                method: 'GET'
            });
            const data = await response.json();
            console.log(data)
            setBooks(data);
            setChapters([]);
        }

        if (books.length <= 0) {
            fetchBooks();
        }
    }, [selectedBook])

    useEffect(() => {
        const fetchChapters = async() => {
            console.debug(selectedBook)
            const book_id = selectedBook.ta_book_id;
            const response = await csrfFetch('/api/books/fetch-chapters', {
                method: 'POST',
                body: JSON.stringify({ book_id }),
            });
            const data = await response.json();
            setChapters(data)
        }
        if (selectedBook && chapters.length <= 0) {
            fetchChapters();
        }
    }, [chapters])

    const updateUsageCountPopup = () => {
        setShowNotificationPopupContainer(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (userId === 1 || userId === null) {
            setNotificationPopupContainer(signUpDiv);
        } else {
            setNotificationPopupContainer(pricingDiv);
        }
    }

    const fetchUsageCount = async() => {
        const response = await csrfFetch('/api/teks/fetch-user-content-count', {
            method: 'POST',
            body: JSON.stringify({
                userId,
            }),
        })
        const data = await response.json();
        return data.userContentCount;
    }

    const incrementUsageCount = () => {
        if (!userId || userId === 1) {
            setUsageCount(parseInt(usageCount) + 1);
            setCookie('usageCount', parseInt(usageCount) + 1, { path: '/' });
        } else {
            setCookie('usageCount', 0, { path: '/' });
        }
    };

    const validateUsage = async() => {
        try {
            if (userId === 1 && usageCount > 2) {
                return false;
            } else if (sessionSubscription?.is_active) {
                return true;
            } else if (userId !== 1) {
                let userUsageCount = await fetchUsageCount();
                if (userUsageCount > 4) {
                    return false;
                }
            } else {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
        //Don't block errors
        return true;
    }

    const createSection = async(e, worksheet_section, topic, type, responseSet, missingType, loadingSection, addToResponse = false) => {
        e.preventDefault();
        if (!type) {
            missingType(true);
            return;
        }

        if (!chapter || !selectedBook || !section) {
            setMissingFormError(true);
            return;
        }

        if (userId === 1) {
            incrementUsageCount();
        }

        try {
            const isValidAttempt = await validateUsage();
            console.log(isValidAttempt)
            if (isValidAttempt) {
                loadingSection(true);
                const book = selectedBook.book_name;
                const user_id = userId;
                const worksheet_chapter = chapter.chapter_name

                const worksheetSection = {
                    user_id,
                    book,
                    worksheet_chapter,
                    worksheet_section,
                    topic,
                    type,
                    language
                }
                const response = await csrfFetch('/api/lessons/create-worksheet-section', {
                    method: "POST",
                    body: JSON.stringify(worksheetSection),
                });
                const data = await response.json();
                if (data) {
                    loadingSection(false);
                    responseSet(data);
                    if (addToResponse) {
                        addToSection(data);
                        setShowAddText(false);
                    }
                }
            } else {
                updateUsageCountPopup();
            }
        } catch(error) {
            console.log(error)
        }
    }

    const createWorksheet = async() => {
        if (userId === 1) {
            updateUsageCountPopup()
            return;
        } else {
            const book = selectedBook.book_name;
            const user_id = userId;
            const worksheet_chapter = chapter.chapter_name
            const worksheet_topics = [topic1, topic2, topic3];
            const worksheet_section = section;
            const types = [worksheetType1, worksheetType2, worksheetType3];
            const content = [response1, response2, response3];
            const worksheetSection = {
                user_id,
                book,
                worksheet_chapter,
                worksheet_section,
                worksheet_topics,
                types,
                content,
                language
            }
            const response = await csrfFetch('/api/lessons/create-worksheet', {
                method: "POST",
                body: JSON.stringify(worksheetSection),
            });
            const data = await response.json();
            console.log(data)
        }
    }

    const addToSection = (text) => {
        setResponse1(response1 + '\n' + text);
    }

    const languages = ["English", "Spanish", "French", "German", "Latin"]

    const worksheetTypes = [
        "Content Passage",
        "Bell Ringer",
        "Reading Passage",
        "Guided Notes",
        "Vocabulary Worksheet",
        "Study Guide",
        "Practice Worksheet",
        "Problem Set",
        "Fill-in-the-Blanks Worksheet",
        "Quiz",
        "Word Search Worksheet",
        "Matching Worksheet",
        "Critical Thinking Worksheet",
        "Data Analysis Worksheet",
        "Case Study Worksheet",
        "Research or Inquiry-Based Worksheet",
        "Review Sheet",
        "Worksheet on Scientific Method",
        "Exploration Worksheet",
        "Worksheet on Calculations or Formulas",
        "Engineering Design Worksheet",
        "Error Analysis Worksheet",
        "Hypothesis Worksheet",
        "Comparison Worksheet",
        "Coding Worksheet",
        "Computational Thinking Worksheet",
        "Energy Flow Worksheet",
        "Diagram Labeling Worksheet",
        "Simulation Worksheet",
        "Field Study Worksheet",
        "Cause and Effect Worksheet",
        "Historical Context Worksheet",
        "Algorithm Design Worksheet",
        "Hypothesis Testing Worksheet",
        "Concept Application Worksheet"
    ];

    const setSelectedBookSubject = async(e, book) => {
        setSelectedBook(book);
        setChapters([]);
        setSections([]);
        setTopics([]);
    }

    const setChapterSections = async(e, bookChapter) => {
        setChapter(bookChapter)
        const chapterSections = JSON.parse(bookChapter.sections);
        setSections(chapterSections);

        if (chapter && selectedBook && section) {
            setMissingFormError(false);
        }
    }

    const setSectionTopics = async(e, sectt) => {
        setSection(sectt)
        const sectionTopics = sectt.topics;
        setTopics(sectionTopics);

        if (missingFormError) {
            setMissingFormError(false);
        }
    }

    const updateLanguage = (e) => {
        const newLanguage = e.target.value;
        setLanguage(newLanguage)
    }

    const updateWorksheetType = (e, setType, setMissingTypeError) => {
        e.preventDefault();
        setType(e.target.value);
        setMissingTypeError(false);
    }

    return (
        <div className="w-screen md:w-full flex flex-col">
            {showNotificationPopupContainer && <div>{notificationPopupContainer}</div>}
            <div className="h-min-[100%] flex font-ivyora-display w-full">
            <DetailedDashboard sessionUser={sessionUser} />
            {showNotificationContainer &&
                <div className="w-full flex flex-col items-center justify-center mt-12">
                    {notificationContainer}
                </div>
            }
            {!showNotificationContainer &&
                <div className={!notificationPopupContainer ? "w-full flex flex-col items-center justify-center mt-12" : "w-full flex flex-col items-center justify-center mt-12"}>
                    <div className="pl-4 md:pl-0 col-span-12 mt-4">
                        <p className="font-ivyora-display text-2xl tracking-wide text-indigo-600">
                            STEM Worksheet Builder For Teachers
                        </p>
                        <div className="flex">
                            <p className="pl-2 font-ivyora-display mt-2 text-sm tracking-wide">Your classroom companion for educational resources.</p>
                            <Lightning className="pt-2" />
                        </div>
                    </div>
                    {missingFormError && <p className="text-red-600 mt-8">Complete The Form</p>}
                    <div className="flex flex-col md:flex-row justify-between mt-8">
                        <div className="px-4">
                            <p class={!selectedBook ? "px-24 mb-1" : "mb-1"}>Subject</p>
                            <select
                                id="subject-select"
                                class={!selectedBook ? "h-8 mx-12 w-[200px] animate-pulse shadow-[0_0_8px_2px_rgba(0,255,255,0.5)] focus:shadow-[0_0_12px_4px_rgba(0,255,255,0.75)] outline-none" : "w-[200px]"}
                                onChange={(e) => setSelectedBookSubject(e, books[e.target.selectedIndex - 1])}
                            >
                                <option>Select A Subject</option>
                                {books?.map((book, index) => (
                                    <option key={index} id={book.book_name}>{book.book_name}</option>
                                ))}
                            </select>
                        </div>
                        {selectedBook && <div className="flex flex-col md:flex-row">
                            <div className="px-4">
                                <p className="mb-1">Chapter</p>
                                <select
                                    id="chapter-select"
                                    class={!chapter ? "w-[200px] animate-pulse shadow-[0_0_8px_2px_rgba(0,255,255,0.5)] focus:shadow-[0_0_12px_4px_rgba(0,255,255,0.75)] outline-none" : "w-[200px]"}
                                    onChange={(e) => setChapterSections(e, chapters[e.target.selectedIndex - 1])}
                                >
                                    <option>Select A Chapter</option>
                                    {chapters?.map((bookChapter, index) => (
                                        <option key={index} id={bookChapter.chapter_name}>{bookChapter.chapter_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="px-4">
                                <p className="mb-1">Section</p>
                                <select
                                    id="section-select"
                                    className={topics.length <= 0 && chapter ? "w-[200px] animate-pulse shadow-[0_0_8px_2px_rgba(0,255,255,0.5)] focus:shadow-[0_0_12px_4px_rgba(0,255,255,0.75)] outline-none" : "w-[200px]"}
                                    onChange={(e) => setSectionTopics(e, sections[e.target.selectedIndex - 1])}
                                >
                                    <option>{sections.length > 0 ? "" : "Select a chapter first"}</option>
                                    {sections?.map((sect, index) => (
                                        <option key={index} id={sect.sectionName}>{sect.sectionName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="px-4">
                                <p className="mb-1">Language</p>
                                <select
                                    id="select-language"
                                    className="w-[200px]"
                                    onChange={(e) => updateLanguage(e)}
                                >
                                    {languages?.map((language, index) => (
                                        <option key={index} id={language}>{language}</option>
                                    ))}
                                </select>
                            </div>
                        </div>}
                    </div>
                    <div className="md:w-[800px] mt-6 overflow-y-auto px-8 md:px-2">
                        <p>Select a subject, chapter, section, and worksheet type - topics are optional. Afterwards,
                            click the create button to generate a worksheet section. Additional sections can be added, worksheets can be edited and printed.
                        </p>
                    </div>
                <div className="md:mt-12 md:w-[900px] h-auto min-h-[800px]">
                    {showLoadingSection1 &&
                        <div className="w-full flex flex-col items-center justify-center mt-32">
                            <LoadingIcon class="spin h-32" />
                            <p className="animate-pulse">Loading</p>
                        </div>}
                    {response1 &&
                        <div className="flex flex-col">
                            <div className="flex justify-end mb-4">
                                <PrintButton content={response1} />
                                <Share />
                            </div>
                            <RichTextEditor response={response1} setResponse={setResponse1} />
                            <button className="self-end bg-indigo-600 h-8 w-[160px] md:mr-2 mt-2 text-white rounded-md" onClick={createWorksheet}>Save Worksheet</button>
                        </div>
                    }
                    {!showLoadingSection1 &&
                        <div>
                            <div className="flex flex-col flex-col md:flex-row mt-12 w-full whitespace-pre-wrap md:px-14 flex justify-center items-center md:space-x-4">
                                {!response1 && <div>
                                    <p>Topic ***Optional***</p>
                                    <select
                                        id="topic-1-select"
                                        className="w-[260px] h-8 md:px-2"
                                        onChange={(e) => setTopic1(e.target.value)}
                                    >
                                        <option></option>
                                        {sections && topics.length === 0 &&
                                            <option>No topics for this section</option>
                                        }
                                        {topics?.map((top, index) => (
                                            <option key={index} id={top}>{top}</option>
                                        ))}
                                    </select>
                                </div>}
                                {!response1 && <div>
                                    {missingTypeError1 && <p className="text-red-600">Worksheet Type Required</p>}
                                    <p>Select Worksheet Type</p>
                                    <select
                                        id="worksheet-type-1-select"
                                        className="w-[260px] h-8 md:px-2"
                                        onChange={(e) => updateWorksheetType(e, setWorksheetType1, setMissingTypeError1)}
                                    >
                                        <option></option>
                                        {worksheetTypes?.map((type, index) => (
                                            <option key={index} id={type}>{type}</option>
                                        ))}
                                    </select>
                                </div>}
                                {!response1 &&
                                <button
                                    className="bg-indigo-600 h-10 w-[180px] mt-8 text-white rounded-md hover:bg-indigo-500"
                                    onClick={(e) => createSection(e, section, topic1, worksheetType1, setResponse1, setMissingTypeError1, setShowLoadingSection1)}  //pass in key and then use a state variable object to store data, this will be used to save the whole worksheet
                                >
                                    Create
                                </button>}
                            </div>
                    </div>}
                    {showAddText && <div className="w-full flex justify-center">
                        <p className="text-xl mt-4">Add Sections</p>
                    </div>}
                    {showLoadingSection2 &&
                        <div className="w-full flex flex-col items-center justify-center mt-32">
                            <LoadingIcon class="spin h-32" />
                            <p className="animate-pulse">Loading</p>
                        </div>}
                    {!showLoadingSection2 &&
                        <div className={response1 ? "" : "disabled opacity-60"}>
                            <div className="mt-12 w-full flex-col md:flex-row items-center whitespace-pre-wrap md:px-14 flex justify-center md:space-x-4">
                                {!response2 && <div>
                                    <p>Topic ***Optional***</p>
                                    <select
                                        id="topic-2-select"
                                        className="w-[260px] h-8 md:space-x-4"
                                        onChange={(e) => setTopic2(e.target.value)}
                                    >
                                        <option></option>
                                        {sections && topics.length === 0 &&
                                            <option>No topics for this section</option>
                                        }
                                        {topics?.map((top, index) => (
                                            <option key={index} id={top}>{top}</option>
                                        ))}
                                    </select>
                                </div>}
                                {!response2 && <div>
                                    {missingTypeError2 && <p className="text-red-600">Worksheet Type Required</p>}
                                    <p>Select Worksheet Type</p>
                                    <select
                                        id="worksheet-type-2-select"
                                        className="w-[260px] h-8 md:space-x-4"
                                        onChange={(e) => updateWorksheetType(e, setWorksheetType2, setMissingTypeError2)}
                                    >
                                        <option></option>
                                        {worksheetTypes?.map((type, index) => (
                                            <option key={index} id={type}>{type}</option>
                                        ))}
                                    </select>
                                </div>}
                                {!response2 &&
                                    <div>
                                        {sessionUser &&
                                        <button
                                            className="bg-indigo-600 h-10 w-[180px] mt-8 text-white rounded-md hover:bg-indigo-500"
                                            onClick={!response1 ? () => { return } : (e) => createSection(e, section, topic2, worksheetType2, setResponse2, setMissingTypeError2, setShowLoadingSection2, true)}  //pass in key and then use a state variable object to store data, this will be used to save the whole worksheet
                                        >
                                            Create
                                        </button>}
                                        {!sessionUser &&
                                            <button
                                                className="bg-indigo-600 h-10 w-[180px] mt-8 text-white rounded-md hover:bg-indigo-500"
                                                onClick={!response1 ? () => { return } : () => updateUsageCountPopup() }
                                            >
                                                Create
                                            </button>}
                                    </div>}
                            </div>
                        </div>}
                    {showLoadingSection3 &&
                        <div className="w-full flex flex-col items-center justify-center mt-32">
                            <LoadingIcon class="spin h-32" />
                            <p className="animate-pulse">Loading</p>
                        </div>}
                    {!showLoadingSection3 &&
                        <div className={response1 ? "" : "disabled opacity-60"}>
                            <div className="mt-12 w-full flex-col md:flex-row whitespace-pre-wrap md:px-14 flex justify-center items-center md:space-x-4">
                                {!response3 && <div>
                                    <p>Topic ***Optional***</p>
                                <select
                                    id="topic-3-select"
                                    className="w-[260px] h-8"
                                    onChange={(e) => setTopic3(e.target.value)}
                                >
                                    <option></option>
                                    {sections && topics.length === 0 &&
                                        <option>No topics for this section</option>
                                    }
                                    {topics?.map((top, index) => (
                                        <option key={index} id={top}>{top}</option>
                                    ))}
                                </select>
                            </div>}
                            {!response3 && <div>
                                {missingTypeError3 && <p className="text-red-600">Worksheet Type Required</p>}
                                <p>Select Worksheet Type</p>
                                <select
                                    id="worksheet-type-3-select"
                                    className="w-[260px] h-8"
                                    onChange={(e) => updateWorksheetType(e, setWorksheetType3, setMissingTypeError3)}
                                >
                                    <option></option>
                                    {worksheetTypes?.map((type, index) => (
                                        <option key={index} key={type}>{type}</option>
                                    ))}
                                </select>
                            </div>}
                                {!response3 &&
                                <button
                                    className="bg-indigo-600 h-10 w-[180px] mt-8 text-white rounded-md hover:bg-indigo-500"
                                    onClick={!response1 ? () => { return } : (e) => createSection(e, section, topic3, worksheetType3, setResponse3, setMissingTypeError3, setShowLoadingSection3, true)}  //pass in key and then use a state variable object to store data, this will be used to save the whole worksheet
                                >
                                    Create
                                </button>}
                            </div>
                        </div>}
                </div>
                </div>}
        </div>
            {!showCookiesBanner && <AdBanner />}
            {showCookiesBanner && <CookiesBanner setShowCookiesBanner={setShowCookiesBanner} />}
            {!showCookiesBanner && <Footer />}
        </div>
    )
}

export default AdvSandbox;
