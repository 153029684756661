import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Route, Switch, useParams } from "react-router-dom";
import LoginFormPage from "./components/LoginFormPage";
import SignupFormPage from "./components/SignupFormPage";
import * as sessionActions from "./store/session";
import ProfilePage from "./components/ProfilePage";
import SitePolicies from "./components/SitePolicies";
import CreateBlogpost from "./components/Blogpost";
import BlogPosts from "./components/Blogpost/blogposts.js";
import ResetPasswordForm from "./components/ResetPassword/index.js"
import ResetPasswordPage from "./components/ResetPassword/ResetPasswordPage.js"
import FinalizeAccount from "./components/ResetPassword/FinalizeAccount.js";
import PasswordUpdated from "./components/ResetPassword/PasswordUpdated.js"
import Search from "./components/Search"
import TestPayment from "./components/Payment";
import PaymentWebHook from "./components/PaymentWebHook";
import About from "./components/About";
import WelcomeGuide from "./components/WelcomeGuide";
import Teks from "./components/Teks/"
import FreeStyle from "./components/FreeStyle";
import MyContent from "./components/MyContent";
import SignUpPage from "./components/SignUpPage";
import MyAccount from "./components/MyAccount";
import RegisterAccount from "./components/RegisterAccount";
import EmailDashboard from "./components/Emails";
import InspireDashboard from "./components/InspireDashboard";
import Isd from "./components/Isd";
import MainGen from "./components/MainGen";
import Resource from "./components/Resource";
import AdvSandbox from "./components/StemGenerator";
import StemGenerator from "./components/StemGenerator";
import ContentFeed from "./components/ContentFeed";
import ContentEditor from "./components/ContentFeed/ContentEditor";

function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    dispatch(sessionActions.restoreUser()).then(() => setIsLoaded(true));
  }, [dispatch]);

  return (
    <>
      {isLoaded && (
        <Switch>
          <Route exact path="/">
            <Helmet>
              <title>Education Resource Generator</title>
              <meta name="description" content="Lightning fast worksheet generator for teachers. Generate educational resources in an instant." />
              <meta property="og:title" content="Education Resource Generator" />
              <meta property="og:description" content="Lightning fast worksheet generator for teachers. Generate educational resources in an instant." />
              <meta property="og:image" content="https://user-images.githubusercontent.com/74638539/217420111-f38ec093-4b7d-4a82-86d2-0a7ead8a7f7a.jpg" />
              <meta property="og:url" content="https://www.teachersaide.io"/>
              <meta property="og:type" content="website" />
              <meta property="twitter:card" content="https://user-images.githubusercontent.com/74638539/217420111-f38ec093-4b7d-4a82-86d2-0a7ead8a7f7a.jpg" />
              <meta property="twitter:title" content="Education Resource Generator" />
              <meta property="twitter:description" content="Lightning fast worksheet generator for teachers. Generate educational resources in an instant." />
              <meta property="twitter:image" content="https://user-images.githubusercontent.com/74638539/217420111-f38ec093-4b7d-4a82-86d2-0a7ead8a7f7a.jpg" />
              <link rel="canonical" href="https://www.teachersaide.io" />
            </Helmet>
            <StemGenerator />
          </Route>
          <Route exact path="/login">
            <LoginFormPage />
          </Route>
          <Route exact path="/signup">
            <SignupFormPage />
          </Route>
          <Route exact path="/profile">
            <ProfilePage />
          </Route>
          <Route exact path="/policies">
            <SitePolicies />
          </Route>
          <Route exact path="/create-blogpost">
            <CreateBlogpost />
          </Route>
          <Route exact path="/posts/:slug">
            <BlogPosts />
          </Route>
          <Route exact path="/resetpassword">
            <ResetPasswordForm />
          </Route>
          <Route exact path="/update-password/:para">
            <ResetPasswordPage />
          </Route>
          <Route exact path="/PasswordUpdated">
            <PasswordUpdated />
          </Route>
          {/*<Route exact path="/search">*/}
          {/*  <Search />*/}
          {/*</Route>*/}
          <Route exact path="/finalize-account/:para">
            <FinalizeAccount />
          </Route>
          <Route exact path="/educational-resource">
            <Resource />
          </Route>
          <Route exact path="/my-account">
            <MyAccount />
          </Route>
          <Route exact path="/about-us">
            <About />
          </Route>
          <Route exact path="/welcome-guide">
            <WelcomeGuide />
          </Route>
          {/*<Route exact path="/teks">*/}
          {/*  <Teks />*/}
          {/*</Route>*/}
          <Route exact path="/my-content">
            <MyContent />
          </Route>
          <Route exact path="/join-now">
            <SignUpPage />
          </Route>
          <Route exact path="/new-subscription-registration/:para">
            <RegisterAccount />
          </Route>
          <Route exact path="/email-dashboard">
            <EmailDashboard />
          </Route>
          <Route exact path="/lesson-plans">
            <MainGen startingType={"freestyle-lessonplan"} useTek={false} title={'Lesson Plan Builder For Teachers'} />
          </Route>
          <Route exact path="/worksheets">
            <MainGen startingType={"freestyle-worksheet"} useTek={false} title={'Worksheet Builder For Teachers'} />
          </Route>
          <Route exact path="/tests">
            <MainGen startingType={"freestyle-test"} useTek={false} title={'Test Builder For Teachers'} />
          </Route>
          <Route exact path="/teks">
            <MainGen startingType={"tek-lessonplan"} useTek={true} title={'TEK Generator For Teachers'} />
          </Route>
          <Route exact path="/inspire-dashboard">
            <InspireDashboard />
          </Route>
          <Route exact path="/content-feed">
            <ContentFeed />
          </Route>
          <Route exact path="/content-editor">
            <ContentEditor />
          </Route>
          {/*<Route exact path="/isd">*/}
          {/*  <Isd />*/}
          {/*</Route>*/}
          {/*<Route exact path="/main">*/}
          {/*  <MainGen />*/}
          {/*</Route>*/}
        </Switch>
      )}
    </>
  );
}

export default App;
