import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from 'react-router-dom';
import CountdownInsert from '../CountdownTimer/countdownInsert.js';
import * as sessionActions from "../../store/session";
import { csrfFetch } from '../../store/csrf.js';
import { addUserinteraction } from '../../utils/user-tracking.js';

const Dashboard = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const sessionUserId = useSelector((state) => state.session.user);

    const sessionUsedprompt = useSelector((state) => state.session.usedprompt)
    const sessionSubscription = useSelector((state) => state.session.usersubscription);

    const [userLessons, setUserLessons] = useState([]);
    const [isVisible, setIsVisible] = useState(true);
    const [mobile, setMobile] = useState(true);
    //const [userId, setUserId] = useState(1);
    //const [subscriptionTypeId, setSubscriptionTypeId] = useState(0)
    const [lessonplansLocked, setLessonplansLocked] = useState(false);
    const [worksheetsLocked, setWorksheetsLocked] = useState(false);
    const [labsLocked, setLabsLocked] = useState(false);
    const [testsLocked, setTestsLocked] = useState(false);
    const [emailsLocked, setEmailsLocked] = useState(false);
    const [limit, setLimit] = useState(2);

    const user = sessionUser;
    const userId = sessionUser?.id || 1;
    const subscriptionTypeId = sessionSubscription?.subscription_type_id || 0;
    const usedprompts = sessionUsedprompt;
    const isMobile = window.innerWidth <= 640; // Check if the window width is less than or equal to 640px (you can adjust this threshold as needed)

    useEffect(() => {
        async function fetchUsedPrompts() {
            if (user && userId !== 1) {
                dispatch(sessionActions.getUsedprompt(userId));     
                dispatch(sessionActions.getSubscription(userId))    
            }
        }
    
        fetchUsedPrompts();
    }, [user, userId, dispatch]);
    

    useEffect(() => {
        async function updateLocks() {
            if (sessionUsedprompt) {
                const shouldLock = (used, key) => parseInt(used[key], 10) > limit && subscriptionTypeId !== 1;

                setLessonplansLocked(shouldLock(sessionUsedprompt, "used_plans"));
                setWorksheetsLocked(shouldLock(sessionUsedprompt, "used_worksheets"));
                setLabsLocked(shouldLock(sessionUsedprompt, "used_labs"));
                setTestsLocked(shouldLock(sessionUsedprompt, "used_tests"));
                setEmailsLocked(parseInt(sessionUsedprompt["used_emails"], 10) > limit); // Assuming emails are always locked past the limit regardless of subscription
            }
        }

        updateLocks();
    }, [sessionUsedprompt, subscriptionTypeId, limit]);
    
    // Handle fetching user lessons
    useEffect(() => {
        async function fetchData() {
            const response = await csrfFetch("/api/lessons/get-submitted-user-prompts", {
                method: "GET"
            });
            const data = await response.json();
            let userPromptsArray = data.submittedPrompts.map(prompt => {
                return prompt.response;
            });
    
            setUserLessons(data);
        }
    
        fetchData();
    }, []);
    
    // Handle mobile checks
    useEffect(() => {
        if (isMobile) {
            setIsVisible(false);
            setMobile(true);
        }
    }, [isMobile]);

      const logout = (e) => {
        e.preventDefault();
        dispatch(sessionActions.logout());
      };

      const toggleMobileDashboard = (e) => {
        e.preventDefault();
        setIsVisible(true)
      }

      const collapseDashboard = (e) => {
        e.preventDefault();
        setIsVisible(false)
      }

      const addInteraction = () => {
        addUserinteraction(userId, 'click: stripe-sub-link', 'details: click: dashboard', 'dashboard')
      }

    return (
        <div className="min-h-[1000px] w-80">
         {mobile &&
              <div className="cursor-pointer">
              <p className="bg-slate-800 text-center text-[9px] pt-3 animate-flash ring-4 ring-slate-700 ring-opacity-50 fixed inset-y-24 left-0 w-[30px] h-[84px] transform text-white" onClick={toggleMobileDashboard} >
                  <span className="block pl-2">M</span>
                  <span className="block pl-2">E</span>
                  <span className="block pl-2">N</span>
                  <span className="block pl-2">U</span>
              </p>
            </div>
         }
         {isVisible && 
            <div className={`bg-slate-700 h-full flex flex-col w-[300px] ${isVisible ? "transform translate-x-0" : "transform translate-x-full"} transition-transform duration-300 ease-in-out`}>
              <div className="cursor-pointer">
                <p className="bg-slate-800 text-center text-[9px] pt-2 animate-flash ring-4 ring-slate-700 ring-opacity-50 fixed inset-y-24 left-72 w-[30px] h-[84px] transform text-white" onClick={collapseDashboard} >
                    <span className="block pl-2">C</span>
                    <span className="block pl-2">L</span>
                    <span className="block pl-2">O</span>
                    <span className="block pl-2">S</span>
                    <span className="block pl-2">E</span>
                </p>
              </div>
            <div>
                <div className="text-4xl p-4 mb-4 flex flex-row">
                    <img src={require("./images/apple-plane.png")} className="h-[52px] w-[50px]" />
                    <h2 className="text-2xl pt-2 pl-2 font-bold tracking-tight text-white">Teachers-AI-de</h2>
                </div>
                {!subscriptionTypeId && sessionUser 
                 && <CountdownInsert />
                }
                {!subscriptionTypeId
                 && <div className="flex-col justify-center mb-6 pl-8">
                        <p className="text-sm font-bold tracking-tight text-white">Unlock unlimited usage with</p>
                        <a className="text-sm font-bold tracking-tight text-green-400" onClick={addInteraction} href="https://buy.stripe.com/bIYg0413r2W995C3cc">TeachersAIde Unlimited</a>
                    </div>
                }

                <div>
                {!sessionUser &&
                <div className="px-4 mb-10">
                    <div className="flex flex-row p-1">
                        <img src={require("./images/profile3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/login" className="block px-2 py-1 text-white hover:text-zinc-400">Login</NavLink>
                        <p className="block py-1 text-white hover:text-zinc-400">|</p>
                        <NavLink exact to="/signup" className="block px-2 py-1 text-white hover:text-zinc-400">Sign Up</NavLink>
                    </div>
                    <div className="flex flex-row p-1 my-2">
                    <img src={require("./images/lessonplan3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/" className="block px-2 py-1 text-white hover:text-zinc-400">Lessons</NavLink>
                    </div>
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/worksheet3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/worksheets" className="block px-2 py-1 text-white hover:text-zinc-400">Worksheets</NavLink>
                        
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                                Subscribe to access this content.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/lab3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/lab" className="block px-2 py-1 text-white hover:text-zinc-400">Labs</NavLink>
                        <span className="text-2xl">🔒</span>
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                                Subscribe to access this content.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row p-1 my-2">
                        <img src={require("./images/test3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/test" className="block px-2 py-1 text-white hover:text-zinc-400">Tests</NavLink>
                    </div>
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/email3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/generate-email" className="block px-2 py-1 text-white hover:text-zinc-400">Email Gen</NavLink>
                        <span className="text-2xl">🔒</span>
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                                Subscribe to access this content.
                            </div>
                        </div>
                    </div>

                    <div className="mt-10">
                    <h3 className="text-xl font-sans font-normal mb-3 text-white">Latest Prompts:</h3>
                    <NavLink exact to="/profile" className="block px-2 py-1 text-white hover:text-zinc-400 whitespace-normal break-words">Register or Login to view recent prompts</NavLink>
                    </div>
                </div>
                }

                {sessionUser && <div>
                <div className="px-4 mb-10">
                    <div className="flex flex-row p-1 my-2">
                        <img src={require("./images/profile3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/profile" className="block px-2 py-1 text-white hover:text-zinc-400">{sessionUser.first_name}</NavLink>
                        <p className="block py-1 text-white hover:text-zinc-400">|</p>
                        <a onClick={logout} className="block py-1 pl-2 text-white hover:text-zinc-400">Log out</a>
                    </div>

                    {lessonplansLocked &&
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/lessonplan3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/" className="block px-2 py-1 text-white hover:text-zinc-400">Lessons</NavLink>
                        <span className="text-2xl">🔒</span>
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                             Subscribe to access this content.
                            </div>
                        </div>
                    </div>
                    }

                    {!lessonplansLocked &&
                    <div className="flex flex-row p-1 my-2">
                    <img src={require("./images/lessonplan3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/" className="block px-2 py-1 text-white hover:text-zinc-400">Lessons</NavLink>
                    </div>
                    }

                    {worksheetsLocked &&
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/worksheet3d.png")} alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/worksheets" className="block px-2 py-1 text-white hover:text-zinc-400">Worksheets</NavLink>
                        <span className="text-2xl">🔒</span>
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                                Subscribe to access this content.
                            </div>
                        </div>
                    </div>
                    }
                    {!worksheetsLocked &&
                    <div className="flex flex-row p-1 my-2">
                        <img src={require("./images/worksheet3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/worksheets" className="block px-2 py-1 text-white hover:text-zinc-400">Worksheets</NavLink>
                    </div>
                    }

                    {labsLocked &&
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/lab3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/lab" className="block px-2 py-1 text-white hover:text-zinc-400">Labs</NavLink>
                        <span className="text-2xl">🔒</span>
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                                Subscribe to access this content.
                            </div>
                        </div>
                    </div>
                    }
                    {!labsLocked &&
                    <div className="flex flex-row p-1 my-2">
                        <img src={require("./images/lab3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/lab" className="block px-2 py-1 text-white hover:text-zinc-400">Labs</NavLink>
                    </div>}

                    {testsLocked &&
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/test3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/test" className="block px-2 py-1 text-white hover:text-zinc-400">Tests</NavLink>
                        <span className="text-2xl">🔒</span>
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                                Subscribe to access this content.
                            </div>
                        </div>
                    </div>
                    }
                    {!testsLocked &&
                    <div className="flex flex-row p-1 my-2">
                        <img src={require("./images/test3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/test" className="block px-2 py-1 text-white hover:text-zinc-400">Tests</NavLink>
                    </div>
                    }

                    {emailsLocked &&
                    <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/email3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/generate-email" className="block px-2 py-1 text-white hover:text-zinc-400">Email Gen</NavLink>
                        <span className="text-2xl">🔒</span>
                        <div className="absolute pl-[210px] z-[10] pointer-events-none">
                            <div className="opacity-0 group-hover:opacity-100 transform -translate-x-1/2 bg-black text-white text-sm p-2 rounded pointer-events-auto">
                                Subscribe to access this content.
                            </div>
                        </div>
                    </div>
                    }
                    {!emailsLocked &&
                    <div className="flex flex-row p-1 my-2">
                        <img src={require("./images/email3d.png")}  alt="apple image" className="w-18 h-[38px]" />
                        <NavLink exact to="/generate-email" className="block px-2 py-1 text-white hover:text-zinc-400">Email Gen</NavLink>
                    </div>
                    }
                    {user && <div className="flex flex-row p-1 my-2 relative group pointer-events-auto">
                        <img src={require("./images/setting3d.png")}  alt="apple image" className="w-18 h-[44px]" />
                        <NavLink exact to="/settings" className="block px-2 py-1 text-white hover:text-zinc-400">Settings</NavLink>
                    </div>}
                </div>

                
                <div className="px-4">
                    <h4 className="text-xl text-zinc-100 font-sans font-normal mb-6">Latest Prompts:</h4>
                    <div className="flex flex-row p-1 border-2 border-zinc-200 text-sm mb-2 text-white hover:text-zinc-400">
                        <img src={require("./images/lightbulb3d.png")}  alt="apple image" className="w-18 h-[38px] mr-1" />
                        {userLessons.submittedPrompts?.[0]?.promptToken && <NavLink exact to={"/submitted-prompts/" + userLessons.submittedPrompts[0].promptToken}>{userLessons.submittedPrompts[0].response.slice(0, 42) + '...'}</NavLink>}
                    </div>
                    <div className="flex flex-row p-1 border-2 border-zinc-200 text-sm mb-2 text-white hover:text-zinc-400">
                        <img src={require("./images/lightbulb3d.png")}  alt="apple image" className="w-18 h-[38px] mr-1" />
                        {userLessons.submittedPrompts?.[1]?.promptToken && <NavLink exact to={"/submitted-prompts/" + userLessons.submittedPrompts[1].promptToken}>{userLessons.submittedPrompts[1].response.slice(0, 42) + '...'}</NavLink>}
                    </div>
                    <div className="flex flex-row p-1 border-2 border-zinc-200 text-sm mb-2 text-white hover:text-zinc-400">
                        <img src={require("./images/lightbulb3d.png")}  alt="apple image" className="w-18 h-[38px] mr-1" />
                        {userLessons.submittedPrompts?.[2]?.promptToken && <NavLink exact to={"/submitted-prompts/" + userLessons.submittedPrompts[2].promptToken}>{userLessons.submittedPrompts[2].response.slice(0, 42) + '...'}</NavLink>}
                    </div>
                </div>
                </div>}


            </div>
        </div>
    </div>}
    </div>
    )
}

export default Dashboard;
