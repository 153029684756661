import React, {useState, useEffect} from 'react';
import {csrfFetch} from "../../store/csrf";
import DetailedDashboard from "../DetailedDashboard";
import {useDispatch, useSelector} from "react-redux";
import {useCookies} from "react-cookie";
import * as sessionActions from "../../store/session";
import AdBanner from "../AdBanner";
import CookiesBanner from "../MainGen/CookiesBanner";
import Footer from "../Footer";
const ContentFeed = () => {
    const [latestContent, setLatestContent] = useState(null)
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [cookies, setCookie] = useCookies(['usageCount']);
    const [usageCount, setUsageCount] = useState(cookies.usageCount || 0);
    const [userId, setUserId] = useState(null);
    const sessionSubscription = useSelector(state => state.session.usersubscription)
    const [showNotificationContainer, setShowNotificationContainer] = useState(false);
    const [notificationContainer, setNotificationContainer] = useState(null);
    const [notificationPopupContainer, setNotificationPopupContainer] = useState(null);
    const [showNotificationPopupContainer, setShowNotificationPopupContainer] = useState(false);

    useEffect(() => {
        async function setUserAndSubscription() {
            if (sessionUser) {
                setUserId(sessionUser.id);
                dispatch(sessionActions.getSubscription(sessionUser.id));
            } else {
                setUserId(1);
            }
        }

        setUserAndSubscription();
    }, [sessionUser])

    useEffect(() => {
        const fetchLatestContent = async() => {
            try {
                const response = await csrfFetch('/api/lessons/fetch-latest-posts', {
                    method: 'POST',
                })
                const data = await response.json();
                setLatestContent(data.contentArr)
            } catch (error) {
                console.log(error);
            }
        }

        if (!latestContent) {
            fetchLatestContent();
        }
    }, [latestContent])

    return (

        <div className="w-screen md:w-full flex flex-col">
            {showNotificationPopupContainer && <div>{notificationPopupContainer}</div>}
            <div className="h-min-[100%] flex font-ivyora-display w-full">
                <DetailedDashboard sessionUser={sessionUser} />
                {showNotificationContainer &&
                    <div className="w-full flex flex-col items-center justify-center mt-12">
                        {notificationContainer}
                    </div>
                }
                {!showNotificationContainer &&
                    <div className="flex justify-center w-full mt-16 mb-32">
                        <table className="px-32 w-[1000px]">
                            <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Type</th>
                                <th>Content</th>
                                <th>Grade</th>
                                <th>Language</th>

                            </tr>
                            </thead>
                            <tbody className="space-y-4">
                            {latestContent?.map((content) => {
                                let type = "Worksheet";
                                let subject = "";
                                let grade = '8-12'
                                let urlType = 'freestyle_worksheet_id';
                                let contentId = null;
                                if (content.freestyle_lessonplan_id) {
                                    type = "Lesson Plan";
                                    subject = content.subject;
                                    grade = content.grade;
                                    urlType = 'freestyle_lessonplan_id';
                                    contentId = content.freestyle_lessonplan_id;
                                } else if (content.freestyle_test_id) {
                                    type = "Test";
                                    subject = content.subject;
                                    grade = content.grade;
                                    urlType = 'freestyle_test_id';
                                    contentId = content.freestyle_test_id;
                                } else if (content.freestyle_worksheet_id) {
                                    type = "Worksheet";
                                    subject = content.subject;
                                    grade = content.grade;
                                    urlType = 'freestyle_worksheet_id';
                                    contentId = content.freestyle_worksheet_id;
                                } else if (content.worksheet_section_id) {
                                    type = "Worksheet";
                                    subject = content.chapter;
                                    grade = content.grade;
                                    urlType = 'worksheet_section_id';
                                    contentId = content.worksheet_section_id;
                                }

                                return (
                                    <tr
                                        key={content.id} className="border-b cursor-pointer hover:bg-indigo-50 bg-opacity-10"
                                        onClick={() => {
                                            window.location = '/content-editor' + '?' + urlType + '=' + contentId
                                        }}
                                    >
                                        <td className="px-4 py-2">{type}</td>
                                        <td className="px-4 py-2">{subject}</td>
                                        <td className="px-4 py-2">{content.content.slice(0, 120)}</td>
                                        <td className="px-4 py-2">{grade}</td>
                                        <td className="px-4 py-2">{content.language}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>}
            </div>
        </div>
    )
}

export default ContentFeed;
