import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import * as sessionActions from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as TestIcon } from "../Assets/test.svg"
import { ReactComponent as LessonPlanIcon } from "../Assets/planner.svg"
import { ReactComponent as StemWorksheetIcon } from "../Assets/stem-worksheet.svg"
import { ReactComponent as WorksheetIcon } from "../Assets/worksheet.svg"
import { ReactComponent as TeksIcon } from "../Assets/Teks.svg"
import DashboardContentFeed from "../ContentFeed/DashboardContentFeed";

const DetailedDashboard = ({ sessionUser }) => {
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(window.innerWidth >= 640);
    const [mobile, setMobile] = useState(true);

    const logout = (e) => {
        e.preventDefault();
        dispatch(sessionActions.logout());
    };

    const navToTab = (location) => {
        window.location = location;
    }

    const toggleMobileDashboard = (e) => {
        e.preventDefault();
        setIsVisible(true)
    }

    const collapseDashboard = (e) => {
        e.preventDefault();
        setIsVisible(false)
    }

    return (
        <div>
            {!isVisible &&
                <div className="cursor-pointer opacity-80">
                    <p className="z-1 bg-indigo-600 text-center text-[9px] pt-3 animate-flash ring-4 ring-indigo-600 ring-opacity-50 absolute inset-y-32 left-0 w-[30px] h-[84px] transform text-white" onClick={toggleMobileDashboard} >
                        <span className="block pl-2">M</span>
                        <span className="block pl-2">E</span>
                        <span className="block pl-2">N</span>
                        <span className="block pl-2">U</span>
                    </p>
                </div>
            }
            {isVisible &&
                <div className="animate-slideIn w-[290px] min-h-[100%] h-[full] bg-indigo-600 flex flex-col pl-6 font-ivyora-display">
                    <div className="cursor-pointer opacity-80">
                        <p className="bg-indigo-600 text-[9px] pt-2 animate-flash ring-4 ring-indigo-600 ring-opacity-50 absolute inset-y-32 left-72 w-[30px] h-[84px] transform text-white" onClick={collapseDashboard} >
                            <span className="block pl-2">C</span>
                            <span className="block pl-2">L</span>
                            <span className="block pl-2">O</span>
                            <span className="block pl-2">S</span>
                            <span className="block pl-2">E</span>
                        </p>
                    </div>
                    <div className="flex items-center mt-12">
                        <p onClick={() => navToTab('/')} className="text-white text-3xl cursor-pointer">TeachersAide</p>
                        <img className="w-[30px] ml-2 pt-1" src={require('../Assets/dog-book-logo-glasses.png')}/>
                    </div>

                    <div className="flex flex-row p-1 mb-2">
                        {sessionUser ?
                            <p onClick={e => logout(e)}
                               className="block px-2 py-1 text-white hover:text-zinc-400 cursor-pointer">Logout</p> :
                            <NavLink exact to="/login"
                                     className="block px-2 py-1 text-white hover:text-zinc-400">Login</NavLink>}
                        <p className="block py-1 text-white hover:text-zinc-400">|</p>
                        {sessionUser ?
                            <NavLink exact to="/my-account" className="block px-2 py-1 text-white hover:text-zinc-400">My
                                Account</NavLink> :
                            <NavLink exact to="/signup" className="block px-2 py-1 text-white hover:text-zinc-400">Sign
                                Up</NavLink>
                        }
                    </div>
                    <div className="flex flex-col">
                        <div className="flex items-center py-2">
                            <StemWorksheetIcon/>
                            <p onClick={() => navToTab('/')}
                               className="block px-2 py-1 text-white hover:text-zinc-400 cursor-pointer">STEM
                                Worksheets</p>
                        </div>
                        <div className="flex items-center py-2">
                            <LessonPlanIcon/>
                            <p onClick={() => navToTab('/lesson-plans')}
                               className="block px-2 py-1 text-white hover:text-zinc-400 cursor-pointer">Lesson
                                Plans</p>
                        </div>
                        <div className="flex items-center py-2">
                            <WorksheetIcon/>
                            <p onClick={() => navToTab('/worksheets')}
                               className="block px-2 py-1 text-white hover:text-zinc-400 cursor-pointer">Worksheets</p>
                        </div>
                        <div className="flex items-center py-2">
                            <TestIcon/>
                            <p onClick={() => navToTab('/tests')}
                               className="block px-2 py-1 text-white hover:text-zinc-400 cursor-pointer">Tests</p>
                        </div>
                        <div className="flex items-center py-2">
                            <TeksIcon/>
                            <p onClick={() => navToTab('/teks')}
                               className="block px-2 py-1 text-white hover:text-zinc-400 cursor-pointer">TEKS</p>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <DashboardContentFeed />
                    </div>
                </div>}
        </div>
    )
}

export default DetailedDashboard;
