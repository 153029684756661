import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as sessionActions from "../../store/session";
import TurnstileWidget from "../TurnstileWidget";

function SignupFormPopup({title, description}) {
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state.session.user);
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [isVerified, setIsVerified] = useState(true);
  const [canAccess, setCanAccess] = useState(false);
  const [titleText, setTitleText] = useState(title ?? 'Unlock Full Access With A Free Account');
  const [descriptionText, setDescriptionText] = useState(description ?? 'Create an account to unlock full site usage. It\'s free to join, no CC required.');


  useEffect(() => {
    if (isVerified) {
      setCanAccess(true);
    }
  }, [isVerified])

  // if (sessionUser) if (sessionUser) window.location = '/'; //return <Redirect to="/teks" />;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!canAccess) {
      setErrors(["Please complete the security challenge"]);
      return;
    }
    if (password === confirmPassword && canAccess) {
      setErrors([]);
      return dispatch(sessionActions.signup({ first_name, last_name, email, username, password }))
          .then(data => {window.location.reload()})
        .catch(async (res) => {
          const data = await res.json();
          if (data && data.errors) {
            setErrors(data.errors)
          }
        });
    } else if (password !== confirmPassword && canAccess) {
        return setErrors(['Confirm Password field must be the same as the Password field']);
    } if (sessionUser) {
      window.location.reload();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen font-ivyora-display">
      <img src={require("../Assets/dog-book-logo-glasses.png")}  alt="apple image" className="w-25 h-[80px]" />
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 font-ivyora-display">{titleText}</h2>
      <p class="mt-2 text-center text-sm text-gray-600 font-ivyora-display">
        {descriptionText}
      </p>  
      <p class="mt-2 text-center text-sm text-indigo-600 hover:text-indigo-400">
        Already a user? <a href='/login'>Log in here</a>
      </p>  
    <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center sm:w-[450px] mt-8">
      <ul>
        {errors.map((error, idx) => <li key={idx}>{error}</li>)}
      </ul>
        <input
          type="text"
          value={first_name}
          onChange={(e) => setFirst_Name(e.target.value)}
          required
          className="h-10 border border-grey-100 px-4 w-full"
          placeholder="First Name"
        />
        <input
          type="text"
          value={last_name}
          onChange={(e) => setLast_Name(e.target.value)}
          required
          className="h-10 border border-grey-100 px-4 w-full"
          placeholder="Last Name"
        />
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="h-10 border border-grey-100 px-4 w-full"
          placeholder="Email"
        />
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="h-10 border border-grey-100 px-4 w-full"
          placeholder="Username"
          />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="h-10 border border-grey-100 px-4 w-full"
          placeholder="Password"
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          className="h-10 border border-grey-100 px-4 w-full mb-4"
          placeholder="Confirm Password"
        />
      <TurnstileWidget setIsVerified={setIsVerified} />
        <label class="flex justify-between items-center mt-6 mb-6">
          <input type="checkbox" class="form-checkbox h-4 w-5 text-gray-600" required></input>
          <span class="text-gray-600 text-sm">I agree to <a href='/Policies' className="text-indigo-800">site policy</a></span>
        </label>      
        <button type="submit" className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"><i class="fa-solid fa-lock color-gray-300 pt-1 mr-2"></i> Create</button>
    </form>
    </div>
  );
}

export default SignupFormPopup;
