import React, {useEffect, useState} from 'react';
import {csrfFetch} from "../../store/csrf";
import AdContainer from "./AdContainer";

const AdBanner = () => {
    const [advGroupId, setAdvGroupId] = useState(1);
    const [advsArr, setAdvsArr] = useState([]);
    const [adIndex, setAdIndex] = useState(0);

    useEffect(() => {
        if (advsArr.length === 0) {
            async function getAdvs() {
                const ta_adv_group_id = advGroupId;
                const response = await csrfFetch('/api/advs/get-advs-by-group', {
                    method: 'POST',
                    body: JSON.stringify({
                        ta_adv_group_id
                    }),
                });
                const data = await response.json();
                const randomizedAddArr = [];
                data.advs.forEach(adv => {
                    if (randomizedAddArr.length === 0) {
                        randomizedAddArr.push(adv);
                    } else {
                        let randomIndex = Math.floor(Math.random() * randomizedAddArr.length - 1);
                        randomizedAddArr.splice(randomIndex, 0, adv)
                    }

                })
                setAdvsArr(randomizedAddArr)
            }
            getAdvs();
        }
    }, [advsArr])

    useEffect(() => {
        if (advsArr.length > 0) {
            const intervalId = setInterval(() => {
                setAdIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % advsArr.length;
                    return newIndex;
                });
            }, 6000);
            return () => clearInterval(intervalId); // Cleanup interval on unmount or dependency change
        }
    }, [advsArr.length]);

    return(
        <div>
            <div className="w-full h-[100px] bg-black opacity-80 fixed bottom-0 flex justify-center items-center">
                {advsArr.length > 0 && (
                    <AdContainer
                        key={adIndex} // Force re-render when adIndex changes
                        ad={JSON.stringify(advsArr[adIndex])}
                    />
                )}
            </div>
        </div>
    )
}

export default AdBanner;
