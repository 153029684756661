import React, {useState, useEffect} from 'react';
import {csrfFetch} from "../../../store/csrf";
const DashboardContentFeed = () => {
    const [latestContent, setLatestContent] = useState(null)

    useEffect(() => {
        const fetchLatestContent = async() => {
            try {
                const response = await csrfFetch('/api/lessons/fetch-latest-dashboard-posts', {
                    method: 'POST',
                })
                const data = await response.json();
                console.log(data.contentArr)
                setLatestContent(data.contentArr)
            } catch (error) {
                console.log(error);
            }
        }

        if (!latestContent) {
            fetchLatestContent();
        }
    }, [latestContent])

    const redirectToEditor = async(content) => {
        const contentKey = Object.keys(content)[0];
        window.location = '/content-editor' + '?' + contentKey + '=' + content[contentKey];
    }

    return (

        <div className="md:w-full flex flex-col py-8 pr-2 text-pretty">
            <p className="text-md font-bold text-white text-pretty mb-4">Check out the latest creations from teachers:</p>
            {latestContent?.map((content, index) => (
                <p className="text-white py-1 cursor-pointer hover:text-gray-300 text-xs" key={index}
                    onClick={() => redirectToEditor(content)}
                >
                    {content.content.slice(0, 80)}
                </p>
            ))}
        </div>
    )
}

export default DashboardContentFeed;
