import React, { useRef, useEffect, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './editor.css';

function RichTextEditor({ response, setResponse }) {
    const editorRef = useRef(null);
    const quillInstance = useRef(null);  // Ref to store the Quill instance
    const [text, setText] = useState(response);

    useEffect(() => {
        if (editorRef.current && !quillInstance.current) {
            // Initialize Quill editor only once
            quillInstance.current = new Quill(editorRef.current, {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        ['link', 'image', 'video', 'formula'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                },
                theme: 'snow'
            });

            // Set the initial text in the editor
            quillInstance.current.setText(text);

            // Listen for text changes
            quillInstance.current.on('text-change', () => {
                const newText = quillInstance.current.getText();
                setText(newText);
                setResponse(newText);
            });
        }

        // Clean up Quill editor
        return () => {
            if (quillInstance.current) {
                quillInstance.current.off('text-change');
            }
        };
    }, []);  // Empty dependency array to run only once on mount

    useEffect(() => {
        if (quillInstance.current && response !== text) {
            quillInstance.current.setText(response);
        }
    }, [response]);  // Sync external response with editor if it changes

    return (
        <div>
            <div ref={editorRef} className="editor-content" />
        </div>
    );
}

export default RichTextEditor;
