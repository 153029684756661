import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import {csrfFetch} from "../../../store/csrf";
import DetailedDashboard from "../../DetailedDashboard";
import {useDispatch, useSelector} from "react-redux";
import {useCookies} from "react-cookie";
import * as sessionActions from "../../../store/session";
import { useParams } from 'react-router-dom';
import RichTextEditor from "../RichTextEditor";
import { ReactComponent as Lightning } from "../../Assets/lightning-bolt1.svg"
import PrintButton from "../../PrintButton";
import Share from "../../Share";

const ContentEditor = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [userId, setUserId] = useState(null);
    const [showNotificationContainer, setShowNotificationContainer] = useState(false);
    const [notificationContainer, setNotificationContainer] = useState(null);
    const [notificationPopupContainer, setNotificationPopupContainer] = useState(null);
    const [showNotificationPopupContainer, setShowNotificationPopupContainer] = useState(false);
    const [response, setResponse] = useState("");
    const [searchParams, setSearchParams] = useState(useLocation().search.substring(1).split("="));

    useEffect(() => {
        const fetchContentResource = async() => {
            const key = searchParams[0];
            const id = searchParams[1];

            try {
                const fetchedResource = await csrfFetch('/api/lessons/fetch-content', {
                    method: 'POST',
                    body: JSON.stringify({key, id})
                });

                const data = await fetchedResource.json();
                setResponse(data.fetchedResource.content);
            } catch(error) {
                console.log(error)
            }
        }
        if (searchParams) {
            fetchContentResource();
        }

    }, [searchParams])
    useEffect(() => {
        async function setUserAndSubscription() {
            if (sessionUser) {
                setUserId(sessionUser.id);
                dispatch(sessionActions.getSubscription(sessionUser.id));
            } else {
                setUserId(1);
            }
        }

        setUserAndSubscription();
    }, [sessionUser])


    return (

        <div className="w-screen md:w-full flex flex-col">

            {showNotificationPopupContainer && <div>{notificationPopupContainer}</div>}
            <div className="h-min-[100%] flex font-ivyora-display w-full">
                <DetailedDashboard sessionUser={sessionUser} />
                {showNotificationContainer &&
                    <div className="w-full flex flex-col items-center justify-center mt-12">
                        {notificationContainer}
                    </div>
                }
                {!showNotificationContainer && response &&
                    <div className="flex flex-col items-center w-full mt-16 mb-32 px-2 md:px-16">
                        <div className="pl-4 flex flex-col items-center mt-4 mb-10">
                            <p className="font-ivyora-display text-2xl tracking-wide text-indigo-600">
                                Worksheet Editor
                            </p>
                            <div className="flex">
                                <p className="pl-8 font-ivyora-display mt-2 text-sm tracking-wide">Edit and share recently created worksheets and lesson plans.</p>
                                <Lightning className="pt-2" />
                            </div>
                        </div>
                        <div className="flex w-full justify-end mb-4">
                            <PrintButton content={response} />
                            <Share />
                        </div>
                        <RichTextEditor response={response} setResponse={setResponse} />
                    </div>}
            </div>
        </div>
    )
}

export default ContentEditor;
